
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <div class="sub-card-top"  >
            <page-title></page-title>
            <span class="parent uk-child-width-1-2@m">
              <div>
                <p class="text">
                  経験豊かな社員により、効率的かつ安全なプランをご提案させていただきます。<br>
                  どうすれば商品にダメージ無く運べるのか、また輸入地で取り出しやすいのかを考え、モノに心を込めた丁寧な作業を信条としております。
                </p>
              </div>
              <div class="button-area">
              </div>
            </span>
          </div>

          <div class="sub-card"  >
            <div class="section-title">
              <span >ご契約の流れ</span>
            </div>
            <div class="section-main " >
              <h2 class="heading-number" data-number="01">お見積もりのご依頼</h2>
              まずは、お気軽にお電話またはお問い合わせフォームよりご相談ください。初回お見積は無料です。<br>
              2回目以降のプラン変更は、別途料金1,000円頂きたく思います。

              <h2 class="heading-number" data-number="02">商品お預かり</h2>
              陸送サービス（各オークション会場、指定場所よりお引き取りいたします）または、弊社ヤードへの直接搬入となります。


              <h2 class="heading-number" data-number="03">梱包作業</h2>
              モノに心を込めた丁寧な作業を信条としております。


              <h2 class="heading-number" data-number="04">ご請求</h2>
              請求書を発行いたしますので、期日までにお振込みください。

            </div>
          </div>

          <div class="sub-card"  >
            <div class="section-title">
              <span >よくあるご質問</span>
            </div>
            <div class="section-main " >
              
              準備中・・・・
            </div>
          </div>
          <link-list></link-list>

        </div>
        
      </div>
      
    </div>
    
  </div>
  
</template>


<script>
import LinkList from '../../modules/parts/LinkList.vue'

export default {
  components: {
    LinkList,
  },
  mounted: function() {
    let description = this.commonSetting.jigyoMenuPartsList.Vanning.subject
    document.querySelector("meta[name='description']").setAttribute('content', this.$_chlang(description))
  }
}
</script>



<style scoped lang="scss">
.parallax-bg {
  background-image:url(~@/assets/img/bg-image1.jpg);
}
.sub-card-top{
  background-image:url(~@/assets/img/page-jigyo-top-vanning.jpg);
}

</style>
