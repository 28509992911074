
<template>


<div class="uk-grid-match uk-child-width-1-3@m " uk-scrollspy="cls: uk-animation-slide-bottom-small; target: .uk-inline; delay: 300; repeat: true" uk-grid>
  <div class="uk-inline "  v-for="(info, pageName, i) of pageNames" :key="i" >
      <router-link :to="info.path" style="height:100%; Width:100%;">
        <img :src="require('@/assets/img/' + parts[pageName].image )" class="image-link " alt="">
        <div class="uk-overlay uk-overlay-default uk-position-bottom title-orverlay " >
            <p > {{$_chlang(info.title)}} </p>
        </div>
      </router-link>
  </div>
</div>
  
  
</template>


<script>

export default {
  data: () => ({
    pageNames:{},
    parts:{},
  }),
  created() {
    // メインメニュー作成
    this.pageNames = this.$_menu(this.commonSetting.jigyoMenuList);
    this.parts = this.commonSetting.jigyoMenuPartsList;
  }
}
</script>



<style scoped lang="scss">
.uk-inline{
  height: 30vh
}
.image-link{
  object-fit: cover;
  width: 100%;
  height: 100%
}
.uk-grid-match{
  padding: 2vw ;
}

.uk-grid > * {
  padding: 2vw ;
  img{
    border-radius: 10px ; 
  }
  .uk-overlay-default{
    background:  linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.7),rgba(0,0,0,1));
  }
  .title-orverlay{
    margin:  0 2vw 2vw 2vw;
    border-radius: 0 0 10px 10px ; 
    color: #fff;
    text-shadow: 0.1rem 0.1rem 0.1rem #000000;
    font-size: 1.2rem;
  }
  &:hover {
    animation: poyopoyo 2s ease-out infinite;
    opacity: 1;

      @keyframes poyopoyo {
        0%, 40%, 60%, 80% {
          transform: scale(1);
        }
        50%, 70% {
          transform: scale(0.9);
        }
      }
  }
}
.uk-grid-match{
  margin: 0;
}
</style>
